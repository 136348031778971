import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
export const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "main",
      component: () => import("@/main/Main.vue"),
      redirect: "home",
      children: [
        {
          path: "home",
          name: "Home",
          component: () => import("@/pages/home/Home.vue"),
        },
        {
          path: "about",
          name: "about",
          component: () => import("@/pages/about/About.vue"),
        },
        {
          path: "what-we-do",
          name: "whatwedo",
          component: () => import("@/pages/whatwedo/WhatWeDo.vue"),
        },
        {
          path: "climate-change",
          name: "climate-change",
          component: () => import("@/pages/climate/Climate.vue"),
        },
        {
          path: "gallery",
          name: "gallery",
          component: () => import("@/pages/gallery/Gallery.vue"),
        },
        {
          path: "contact-us",
          name: "contact",
          component: () => import("@/pages/contact/Contact.vue"),
        },
        {
          path: "blogs",
          name: "blogs",
          component: () => import("@/pages/blog/Blog.vue"),
        },
        {
          path: "terms-condition",
          name: "terms-condition",
          component: () => import("@/pages/terms-condition/Terms.vue"),
        },
        {
          path: "blogs-details",
          name: "blogs-details",
          component: () => import("@/pages/blog/components/BlogsDetails.vue"),
        },
        {
          path: "blogs-ParadigmaDetails",
          name: "ParadigmaDetails",
          component: () =>
            import("@/pages/blog/blogs-details/ParadigmaDetails.vue"),
        },
        {
          path: "blogs-IndianCulture",
          name: "IndianCulture",
          component: () =>
            import("@/pages/blog/blogs-details/IndianCulture.vue"),
        },
        {
          path: "blogs-BioMethaneDetails",
          name: "BioMethaneDetails",
          component: () =>
            import("@/pages/blog/blogs-details/BioMethaneDetails.vue"),
        },
        {
          path: "blogs-HighTempDetails",
          name: "HighTempDetails",
          component: () =>
            import("@/pages/blog/blogs-details/HighTempDetails.vue"),
        },
        {
          path: "blogs-GasDistribution",
          name: "GasDistribution",
          component: () =>
            import("@/pages/blog/blogs-details/GasDistribution.vue"),
        },
        {
          path: "blogs-DigesterDetails",
          name: "DigesterDetails",
          component: () =>
            import("@/pages/blog/blogs-details/DigesterDetails.vue"),
        },
      ],
    },
    {
      path: "/",
      name: "whitenavbar",
      component: () => import("@/main/MainDark.vue"),
      redirect: "about",
      children: [],
    },
  ],
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});
export default router;
